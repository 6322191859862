import React from "react"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"


function Newsletters () {
	
	
	const query = graphql`
	      query {
			  allFile(sort: {fields: name, order: ASC}, filter: {relativePath: {glob: "pdf/newsletters/*.pdf"}}) {
			    edges {
			      node {
			        name
			        publicURL
			      }
			    }
			  }
			}
	`


	
	let data = useStaticQuery(query)
	
	const addDate = (image) => {
		let dateParts = image.node.name.toLowerCase().replace("st john newsletter ","").split("-")
		const date = new Date(dateParts[1],dateParts[0]-1, 15)
		image.date = date
		return image
	}

	const getBulletinLi = (image,key) => {

		const options = { year: 'numeric', month: 'long' };
		const name = image.date.toLocaleDateString("en-US", options)
		return getLiFromFile(name, image.node.publicURL, key)
	}

	const getLiFromFile = (name, url, key) => {
		const liClass="download-newsletter-"+key
		const iconClass="download-newsletter-icon-"+key
		return (
			<li className={liClass} key={key}>
					<a href={url} target="_blank">
						{name} <CloudDownloadOutlinedIcon className="download-icon" id={iconClass}/>
					</a>
			</li>
		)
	}

	console.log(data.allFile.edges)
	let bulletinFiles = data.allFile.edges
	
	bulletinFiles = bulletinFiles.map(image => addDate(image))  //Add date to the bulletin objects
	console.log(bulletinFiles)
	bulletinFiles.sort((a, b) => (a.date > b.date) ? -1 : 1)    //Sort the bulletins by date
	console.log(bulletinFiles)
	const bulletins = bulletinFiles.map((image,key) => getBulletinLi(image,key))
	return( 

			<ul className="newsletter-list" id="newsletter-list"> 
				{bulletins}
			</ul>
	)
}

export default Newsletters 