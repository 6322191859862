import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"

import Newsletters from "../../components/our-parish/Newsletters"

import JSONData from "../../../content/our-parish/data.json"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/our_parish.css"


function OurParish () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/about-us/our-parish/*.jpg" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
			}
	`




	let data = useStaticQuery(query)


	let images = data.allFile
	let subheaderData = JSONData.subheader; 

	
	const church_aniversery = images.edges.find(element => element.node.name === 'church_aniversery').node
	const school_photo = images.edges.find(element => element.node.name === 'school').node
	const church_rainbow = images.edges.find(element => element.node.name === 'church_rainbow').node
	const h_and_b = images.edges.find(element => element.node.name === 'h_and_b').node
	const big_house = images.edges.find(element => element.node.name === 'big_house').node
	const met_phil = images.edges.find(element => element.node.name === 'met_phil').node
	const newsletter_thumb = images.edges.find(element => element.node.name === 'newsletter_thumb').node
	const maranatha_north = images.edges.find(element => element.node.name === 'maranatha_north').node
	const location = ""

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content about-us-colors about-us">
				<div>
					<Subheader data={subheaderData} />
				</div>
				<div className="our-parish">
					<div className="our-parish-paragraph">
						<h3 className="block-header">An Introduction</h3>

						Saint John Orthodox Cathedral is a parish within the Antiochian Orthodox Christian Archdiocese in North America. We began in Eagle River in 1972 and today our membership is a little over 100 families. We are not “Orthodox” through ancestral history, as are those who come from Greece or Russia or the Middle East. Instead our founding members were almost entirely “converts” to Orthodoxy from other Christian backgrounds. We still continue to welcome new members who want to become Orthodox, but we also now have new generations who are “cradle Orthodox,” and who are helping to write the next chapter in our history. 
					</div>

					<div className= "mission-statement-wrapper">

						<div className="mission-statement">
							<div className="mission-statement-header">
In 2024 the Parish Council of our Church worked to update our Mission Statement:
							</div>
							<div className="mission-statement-body">
Saint John’s strives to fulfill the mission of Christ’s Church by engaging the world around us and reflecting the light of Christ in the Orthodox way, especially through traditional worship, warm hospitality, and a loving community.
</div>
<div className="our-parish-paragraph">
<br></br>	<br></br>		
<b>The following Core Values are especially important to us:</b>
<br></br>	<br></br>	
<b>Inclusive Communal Worship:</b> The experience of accessible worship is open to all; it includes modern English and congregational singing.
<br></br>	<br></br>	
<b>Community and Fellowship:</b> We endeavor to be a beacon of light to all who are looking for community. We share our lives together through activities and events.
<br></br>	<br></br>	
<b>Christian Education:</b> We believe in the importance of providing ongoing education and discussion for all ages - young and old.
<br></br>	<br></br>	
<b>Hospitality:</b> We want to receive all visitors with a warm welcome, sharing the love of Christ by serving both our members and guests alike.
<br></br>	<br></br>	
<b>Acts of Service:</b> We provide outreach opportunities to help support and encourage the surrounding community and beyond. We believe that to serve others is to serve God and strive to say “yes” to all who ask for help.
<br></br>	<br></br>	
<b>Grace:</b> Our lives are anchored on the fact that we are saved by the grace of God. We aim to live our lives with grace, forgiveness and acceptance for each other and ourselves.


							</div>
						</div>
						<Img 
							fluid={church_aniversery.childImageSharp.fluid}
							alt="" 
							className="church-aniversery-photo"
						/>
						<div className="photo-caption-right">Photo taken April 2, 2017</div>
					</div>

					<div className="our-parish-paragraph clear">
						<h3 className="block-header">More About Us</h3>
						We are an active community with many ways to serve our community, but our identity is derived first of all from our regular, worship together as Orthodox Christians. We encourage congregational singing of many traditional, Orthodox hymns. You are welcome to join us for any of these <Link to="/service-schedule" target="_blank">services</Link>.
					</div>
						
					<div className="our-parish-paragraph">

					We have a <Link to="/saint-johns-school">School </Link> – Preschool through Junior High – with about 60 students and a staff of about 20, many part-time. About half of the students are from our own parish. We welcome all families to learn more about our School as an excellent choice to educate their children.
					</div>
					<Img 
							fluid={school_photo.childImageSharp.fluid}
							alt="" 
							className="school-photo"
						/>
					<div className="our-parish-paragraph">
					Each summer, August 1-5, we sponsor the <Link to="/eagle-river-institute/">Eagle River Institute of Orthodox Christian Studies</Link>. The mission  of the Institute is to provide a forum for Orthodox scholars to address lay people on topics relevant to everyday, Christian life. The Institute also contributes to the greater, educational needs of the Church by publishing its lectures as podcasts. We are very much looking forward to the presenters we have coming this summer. You do not have to be Orthodox to benefit from this stimulating and informative time together, so please consider participating.
					<br/>
					<br/>
					We are also excited about the Montessori-based Christian Education we have for our Children, which we call <Link to="/atrium">“Atrium”</Link> and which is an Orthodox adaptation of the national program called “Catechesis of the Good Shepherd.” This program is for children ages Preschool through 6th grade and takes place on most Sundays after Liturgy. We also have Youth Group activities for Junior High and High School students that meet at the same time.
					<br/>
					<br/>
					There are many other things that go in our parish. You can check the <a href="https://calendar.google.com/calendar/embed?src=0k3i98qf8faoh4893emkkhed7c%40group.calendar.google.com&ctz=America%2FAnchorage">calendar</a> for different events or see the Sunday Bulletins in the menu. 

					</div>
					<br/>
					<div className= "church-rainbow-wrapper">
						<Img 
							fluid={church_rainbow.childImageSharp.fluid}
							alt="" 
							className="church-rainbow-photo"
						/>
						<div className="church-rainbow-text">
	 							We are blessed to live in Eagle River and we have been given a beautiful location that provides a welcoming and peaceful place for anyone who stops by, whether for an afternoon or a lifetime. We have been given much and we want, in turn, to use it for good, as God allows.


						</div>

					</div>

					<div className="our-parish-paragraph clear">
					<br/>

						<h3 className="block-header">A Brief History</h3>
						<Img 
							fluid={h_and_b.childImageSharp.fluid}
							alt="" 
							className="h-and-b-photo"
						/>
						We came into the Orthodox Church as part of the spiritual journey of a group of communities who in the 1980’s and 90’s were known as the “Evangelical Orthodox.” This journey is described in the book, <a className="book-title" href="https://store.ancientfaith.com/becoming-orthodox-a-journey-to-the-ancient-christian-faith/" target="_blank">Becoming Orthodox</a>, by Peter Gillquist and also in the book, <a className="book-title" href="https://store.ancientfaith.com/community-of-grace-an-orthodox-christian-year-in-alaska/" target="_blank" >Community of Grace</a>, by Mary Alice Cook. 
						<br/>
						<br/>

						The local history of our parish began with the ministry of Harold and Barbara Dunaway, who came to Alaska in <b>1968</b> as members of the evangelical organization, Campus Crusade for Christ. In <b>1972</b> Harold and Barbara moved to a large home which came to be nicknamed, the “Big House,” now known as the Saint James House on the Cathedral property. Here they sought to “disciple” young Christians in an extended-family setting inspired by the ministry of Francis and Edith Schaeffer described in their book, “L’abri.”
					</div>
					<div className="our-parish-paragraph">

						<Img 
							fluid={big_house.childImageSharp.fluid}
							alt="" 
							className="big-house-photo"
						/>
					
						In <b>1974</b> a young Church began to form out of this ministry and in that same year became affiliated with other groups that had sprung up in a similar way, disconnected from any denominational ties. Together the leaders of these groups began to research and to seek how a the idea of “Church” could be best expressed. Little did they know that this was the beginning of their journey to Orthodoxy.
						<br/>
						<br/>
						One of the hallmarks of this journey was an emphasis on <b>Christian community</b>, the idea that people could choose to live near each other and better serve each other in many ways. In Eagle River this took the form of young families buying new land that was being developed and building their own simples homes. The end result was a village-like community with over 50 Church families living within a mile radius of Saint John Cathedral. The Cathedral itself was built in <b>1985</b> by the labors and contributions of parishioners.
						<br/>
						<br/>
						<div className="met-phil-photo">
							<Img 
								fluid={met_phil.childImageSharp.fluid}
								alt="" 
								className="met-phil-photo"
							/>
							<span className="photo-caption-right">
								Metropolitan Philip and Patriarch Ignatius IV
							</span>
						</div>
						The other hallmark of this journey was a desire to find a <b>mainstream of Christianity</b> that could be traced all the way back to the New Testament. Here is how our community discovered the Orthodox Church, a faith and a Christian belief and way of life that we saw as pre-dating all the churches and denominations we had known to this point. Eventually this led our communities to mature in doctrine and practice and at last to seek how we could actually become part of the Orthodox Church. In <b>1985</b> the leadership of these 20 or so communities travelled to Istanbul to ask the Patriarch of Constantinople how we could enter. While the answer we received there was not very helpful, it eventually led to the door to Orthodoxy being thrown open to us by Patriarch Ignatius IV in <b>1987</b>. And so most of our communities continued their journey and were officially and organically brought into the great and historic community of Orthodox Churches worldwide in that year by Metropolitan Philip of the Antiochian Archdiocese. It was an exciting and yet humbling time. 
							<br/>
							<br/>
							The decades following saw much maturing and growth, theologically, liturgically, and spiritually. It would be impossible to recall all the many good and kind people that helped us along the way. But it would also be untrue to not admit there were also times of conflict and discouragement along the way. Nevertheless, it has been our firm conviction all along that God did lead us and will continue to lead us on this journey. We want Orthodox Christianity be better known in Eagle River and throughout our North America and we are striving together to know God and to better reflect His love in our world. <b>You are welcome to join us in this mission and on this continuing journey. </b>


					</div>
					<div className="newsletters-wrapper">
						<div className="our-parish-paragraph newsletter-text">
						
							<h3 className="block-header">Archived Newsletters</h3>
							Between 2004 and 2015 a dedicated group of authors, including Kh. Barbara Dunaway, Mary Alice Cook, and Maye Johnson, published an extraordinary newsletter for our parish that contained many interesting stories and pictures. If you want to know some more of the history of our parish during these years, you may enjoy perusing these newsletters.
						<Img 
									fluid={maranatha_north.childImageSharp.fluid}
									alt="" 
									className="maranatha-north-img"
							/>
						<span className="maranatha-caption">This was a fun picture taken by the residents of the St. James house in 1974 as they felt like they had moved to the edge of the wilderness.</span>
						</div>
						<div className="newsletter-download-wrapper">
						<h3>Download Newsletter</h3>
							
							<Newsletters/>
							<Img 
									fluid={newsletter_thumb.childImageSharp.fluid}
									alt="" 
									className="newsletter-thumb"
							/>
						</div>
					</div>
				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default OurParish 
